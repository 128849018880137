[dir='rtl'] {
  .chat-flow {
    &.project-position {
      inset-inline-end: 40px;
    }
    &.chat-position {
      inset-inline-end: 40px;
    }
  }

  .navbar-brand {
    right: 10px !important;
  }

  .ngx-datatable {
    .empty-row {
      text-align: right;
    }
    &.fixed-header {
      .datatable-header {
        .datatable-header-inner .datatable-header-cell {
          text-align: right;
          padding-right: 0 !important;
        }
      }
    }

    .datatable-body {
      .datatable-body-row {
        .datatable-body-cell {
          text-align: right;
          &:last-child {
            text-align: left;
          }
          .dropdown {
            text-align: left;
          }
        }
      }
    }
  }

  .btn-group {
    > .btn-group:not(:last-child) > .btn,
    > .btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
    }

    .dropdown-toggle {
      border-top-left-radius: 3px !important;
      border-bottom-left-radius: 3px !important;
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 2px !important;
  }

  .data-tables-remove-all {
    right: calc(100% - 120px);
  }

  .tooltip {
    .tooltip-arrow {
      top: 0;
      margin-top: 10px;
      transform: scaleX(-1) !important;
      border-width: 0 5px 5px;
    }

    &:is([data-popper-placement='right']) {
      .tooltip-arrow {
        right: 100%;
        margin-right: -6px;
      }
    }

    &:is([data-popper-placement='left']) {
      .tooltip-arrow {
        left: 100%;
        margin-left: -6px;
      }
    }

    .tooltip-arrow::before {
      border-color: white;
      border-style: solid;
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }

  .abp-toast {
    i.fa-times {
      padding-right: 470px !important;
    }
  }

  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .lp-topmenu .lp-sidebar .lp-sidebar-wrapper {
      margin: -51px auto 0 !important;

      nav {
        text-align: right !important;
        padding-right: 275px !important;
      }
    }
  }

  input {
    direction: rtl;
    text-align: right;
  }

  .data-table-actions {
    text-align: left !important;
  }

  .file-select i,
  .attachment-select i {
    left: 10px;
    right: auto;
  }

  .ms-auto {
    margin-left: unset !important;
  }
}

abp-grid-actions {
  .fa {
    margin-inline-start: 4px !important;
  }
}
